import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAPxKlD7r6TWz3iolFFj4-0iO4ul7GrKRQ",
  authDomain: "wedding-117a3.firebaseapp.com",
  databaseURL: "https://wedding-117a3-default-rtdb.firebaseio.com",
  projectId: "wedding-117a3",
  storageBucket: "wedding-117a3.appspot.com",
  messagingSenderId: "786609488974",
  appId: "1:786609488974:web:747cc0f45c8f19b95d6f1f",
  measurementId: "G-CFSZ3HT57Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {
  db,
}