<template>
    <VueModal
      v-model="showingModal"
      :close="closeModal"
      @after-leave="afterLeave"
    >
      <div class="app-modal">
       <slot />
      </div>
    </VueModal>
  </template>
  
  <script setup>
  import { ref, watch } from 'vue';
  
  // eslint-disable-next-line no-undef
  const props = defineProps({
    showing: {
      type: Boolean,
    },
    afterLeave: {
      type: Function,
    },
    focus: {
      type: Boolean,
    },
  });
  
  const showingModal = ref(props.showing);
  // eslint-disable-next-line no-unused-vars
  const closeModal = () => {
    if (props.focus) return;
    showingModal.value = false;
  };
  
  watch(() => props.showing, (value) => {
    showingModal.value = value;
  });
  </script>
  
  <style>
  
    /* app modal */
    .app-modal {
      background: #FFFFFF;
      border-radius: 10px;
      min-width: 300px;
      /* min-height: 306px; */
      height: auto;
      flex-direction: column;
      align-items: center;
      padding: 20px 12px;
      margin: 30px;
    }
  
    .app-modal .modal-title {
      color: #25262B;
      font-weight: 600;
      font-size: 20px;
      line-height: 36px;
      margin-top: 12px;
      margin-bottom: 2px;
    }
  
    .app-modal .modal-description {
      font-size: 14px;
      line-height: 28px;
      color: #666666;
    }
  
    .app-modal .modal-icon-wrapper  {
      border-radius: 50%;
      width: 88px;
      height: 88px;
    }
  
    .app-modal .modal-icon-wrapper i {
      font-size: 48px;
    }
  
  </style>