import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/styles/app.css';
import VueUniversalModal from 'vue-universal-modal';
import 'vue-universal-modal/dist/index.css';


createApp(App).use(VueUniversalModal, {
    teleportTarget: '#app-modal',
    modalComponent: 'VueModal',
  }).mount('#app')

