<template>
  <div class="w-max-md sm:w-max-full mx-auto">
    <MainPage :user="invited"/>
  </div>
</template>

<script>
import MainPage from './components/MainPage.vue'
import AOS from 'aos'
import 'aos/dist/aos.css'


export default {
  name: 'App',
  components: {
    MainPage
  },
  data() {
    return {
      invited: null,
    }
  },
  methods: {
    getUriParam() {
      const mykeysvalue = window.location.search;
      let params = new URLSearchParams(mykeysvalue.replace("&", "+%26+"));
      this.invited = params.get("to");
    }
  },
  mounted() {
    AOS.init()
  },
  created() {
    this.getUriParam();
   
  },
}

</script>

<style>
html{
  scroll-behavior: smooth !important;
}

#app {
  margin: 0 !important;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
  scroll-behavior: smooth;
}


@font-face {
  font-family: "Tahu";
  src: url('@/assets/Tahu.ttf');
}

</style>
