<template>
    <!-- <div class="modal fixed w-full h-full flex items-center justify-center" v-if="openinvitation">
        <div class="modal-content">
            <div style="color:#ceaa4a;" class="text-2xl font-bold mb-4">
                Invitation
            </div>
            <div class="flex items-center justify-center mb-4">
                <img src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/image-dialog.png"
                    alt="Icon Instagram" style="height:150px; width:150px;" />
            </div>
            <div class="text-sm mb-4">
                Kepada/Yth/Bapak/Ibu/Saudara/i
            </div>
            <div style="color:#ceaa4a;" class="text-2xl font-bold mb-4" v-if="!user">
                {{user}}
            </div>
            <div style="color:grey;" class="text-xs italic mb-4">
                *Mohon maaf bila ada kesalahan penulisan nama dan gelar
            </div>
            <p class="open-invitation" @click="closeModalInvition">Open Invitation</p>
        </div>
    </div>
     -->
    <TheModal :showing="openinvitation" :focus="true">
        <div class="flex items-center justify-center text-center px-6">
            <div>
                <div style="color:#ceaa4a;" class="text-2xl font-bold mb-4">
                    Invitation
                </div>
                <div class="flex items-center justify-center mb-4">
                    <img src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/image-dialog.png"
                        alt="Icon Instagram" style="height:150px; width:150px;" />
                </div>
                <div class="text-sm mb-4">
                    Kepada/Yth/Bapak/Ibu/Saudara/i
                </div>
                <div style="color:#ceaa4a;" class="text-2xl font-bold mb-4">
                    {{user}}
                </div>
                <div style="color:grey;" class="text-xs italic mb-4">
                    *Mohon maaf bila ada kesalahan penulisan nama dan gelar
                </div>
                <p class="open-invitation" @click="closeModalInvition">Open Invitation</p>
            </div>
        </div>
    </TheModal>
    <div class="modal flex items-center justify-center" v-if="open">
        <div class="modal-content w-3/5">
            <div class="text-base font-bold my-4">Informasi</div>
            <div class="mb-10" style="color: grey;">{{msg}}</div>
            <button type="button" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                @click.prevent="open = false">
                OK
            </button>
        </div>

    </div>
    <div class="">
        <!-- Header -->
        <div class="h-screen relative bg-[#ffe0d3] mx-auto p-4" style="max-width:70rem">
            <div class="bg-white w-full h-full flex items-center justify-center">
                <div class="text-center">
                    <p class="text-lg mb-8" style="color: #b47854;">THE WEDDING OF</p>
                    <p class="text-[55px] font-sacramento mb-12" style="font-weight: 700; color: #70604f;">
                        Suci
                        <br />
                        &
                        <br />
                        Nu'man
                    </p>
                    <p class="text-md sm:text-xl mb-4" style="color: #b47854;">
                        SABTU, 01 OKTOBER 2022
                    </p>
                    <p class="text-md sm:text-xl mb-4" style="color: #b47854;">
                        Kepada Yth. Bapak/Ibu/Saudara/i
                    </p>
                    <div
                        class="w-fit bg-[#d3b26b] rounded-xl text-center px-6 py-1 text-lg font-bold text-white mx-auto">
                        {{ user }}
                    </div>
                </div>
            </div>
            <img class="absolute top-0 left-0 w-1/4 md:w-64"
                src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/ornamentfloral24.svg"
                alt="Bingkai Top Left" />
            <img class="absolute bottom-0 right-0 w-1/4 md:w-64" style="transform: rotate(180deg);"
                src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/ornamentfloral24.svg"
                alt="Bingkai Bottom Right" />
            <img class="absolute top-0 right-0 w-1/4 md:w-64" style="transform: scaleX(-1);"
                src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/ornamentfloral24.svg"
                alt="Bingkai Top Right" />
            <img class="absolute bottom-0 left-0 w-1/4 md:w-64" style="transform: scaleY(-1);"
                src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/ornamentfloral24.svg"
                alt="Bingkai Bottom Left" />
        </div>
        <!-- Mempelai -->
        <div class="relative mx-auto text-center py-16 bg-white" id="mempelai" style="max-width:70rem">
            <div class="mb-8">
                <img class="mempelai-icon mx-auto"
                    src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/mempelaifloral24.svg"
                    alt="Mempelai" />
                <p class="text-md md:text-xl" style="color: #b47854;">M E M P E L A I</p>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-2" data-aos="zoom-in" data-aos-duration="1500"
                data-aos-delay='500'>
                <div class="flex items-center justify-center">
                    <div class="px-16">
                        <img class="mb-16 mx-auto"
                            src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/cewe.png"
                            alt="Mempelai Pria" style="transform: scaleX(-1);"/>
                        <div class="text-[40px] font-semibold mb-6 font-sacramento" style="color: #B47854;">
                            Suci Sofyati
                        </div>
                        <p class="text-sm font-bold mb-6" style="color: #B47854;">
                            Putri Ke-1 dari Bapak Saifur Roghozi & Ibu Wiratmi
                        </p>
                        <p class="text-sm font-bold mb-6" style="color: #B47854;">
                            Jakarta
                        </p>
                        <div class="instagram-action bg-[#ffe0d3] rounded-full w-fit px-4 py-2.5 mx-auto"
                            v-on:click="instagramAction('https://www.instagram.com/sucisofyzz/')">
                            <img class="inline"
                                src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/miniig.svg"
                                alt="Icon Instagram" />
                            <span class="ml-2.5">
                                sucisofyzz
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex items-center justify-center">
                    <div class="px-16">
                        <img class="mb-16 mx-auto"
                            src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/cowo.png"
                            alt="Mempelai Pria" style="transform: scaleX(-1);"/>
                        <div class="text-[40px] font-semibold mb-6 font-sacramento" style="color: #B47854;">
                            Nu'man Nashif Annawwaf
                        </div>
                        <p class="text-sm font-bold mb-6" style="color: #B47854;">
                            Putra Ke-2 dari Bapak Rido Wahyuri & Ibu Yuli Supriyatin
                        </p>
                        <p class="text-sm font-bold mb-6" style="color: #B47854;">
                            Sukoharjo
                        </p>
                        <div class="instagram-action bg-[#ffe0d3] rounded-full w-fit px-4 py-2.5 mx-auto"
                            v-on:click="instagramAction('https://www.instagram.com/nomannnnn_/')">
                            <img class="inline"
                                src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/miniig.svg"
                                alt="Icon Instagram" />
                            <span class="ml-2.5">
                                nomannnnn_
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Event -->
        <div class="py-16 px-6" id="event"
            style="max-width:70rem; margin-left: auto; margin-right: auto; background-color: #fff6f3;">
            <div class="bg-[#FFBAA6] text-center rounded-3xl px-6 sm:px-[60px] py-3 sm:py-[30px] mx-0 sm:mx-[40px]">
                <div class="mb-8" data-aos="zoom-in" data-aos-duration="1500" data-aos-delay='500'>
                    <img class="mx-auto my-5"
                        src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/specialeventfloral24.svg"
                        alt="Mempelai" />
                    <p class="text-md md:text-xl text-white">OUR WEDDING EVENT</p>
                </div>
                <div class="my-12" data-aos="zoom-in" data-aos-duration="1500" data-aos-delay='500'>
                    <p class="text-white text-lg">
                        Merupakan suatu kehormatan dan kebahagiaan
                        bagi kami atas kehadiran Bapak/Ibu/Saudara/i untuk memberikan doa restu kepada kami.
                    </p>
                </div>
                <div class="my-12 bg-white rounded-xl p-10 text-center" data-aos="zoom-in" data-aos-duration="1500"
                    data-aos-delay='500'>
                    <div class="mb-4">
                        <img class="mx-auto my-5"
                            src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/acarafloral24.svg"
                            alt="Mempelai" />
                        <p style="color: #B47854;">
                            Akad & Resepsi
                        </p>
                    </div>
                    <span id="course_divider">
                        <hr class="mx-auto">
                    </span>
                    <div class="my-6">
                        <div style="color: #70604F;">Sabtu, 01 Oktober 2022</div>
                        <div style="color: #70604F;">09:00 - Selesai</div>
                    </div>
                    <div style="color: #70604F;" class="my-6">
                        Jl. Masjid Al Falah No.14, RT.6/RW.1, Kamal, Kec. Kalideres, Kota Jakarta Barat, Daerah Khusus
                        Ibukota Jakarta 11810
                    </div>
                    <!-- <div class="rounded-full text-xs font-semibold mb-4" style="color: #70604F"
                        v-on:click="addToCalendar">
                        <i class="fa fa-calendar mr-2"></i> Add to Google Calendar
                    </div>
                    
                    <span id="course_divider">
                        <hr class="mx-auto">
                    </span> -->
                </div>
                <div class="my-12 text-center" data-aos="zoom-in" data-aos-duration="1500" data-aos-delay='500'>
                    <p class="text-white text-lg my-6" style="color: white;">Google Maps</p>
                    <div class="my-4">
                        <iframe class="google-map w-full h-[300px] rounded-3xl" style="border:0" loading="lazy"
                            allowfullscreen referrerpolicy="no-referrer-when-downgrade"
                            src="https://maps.google.com/maps?q=arizqie&t=m&z=14&output=embed&iwloc=near">
                        </iframe>
                    </div>
                </div>
                <div class="my-12 text-center" data-aos="zoom-in" data-aos-duration="1500" data-aos-delay='500'>
                    <img class="mx-auto my-5"
                        src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/prokesfloral24.svg"
                        alt="Mempelai" />
                    <p class="text-md md:text-xl text-white">P r o t o k o l K e s e h a t a n</p>
                    <div class="text-white text-md my-6">
                        Demi mendukung kesehatan Bersama alangkah baiknya para tamu yang akan hadir memenuhi protokol
                        kesehatan sebagai berikut:
                    </div>
                    <div class="grid grid-cols-1 sm:grid-cols-3">
                        <div>
                            <img class="mx-auto my-4"
                                src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/prokes1putih.svg"
                                alt="Mempelai" />
                            <div class="text-white">Cuci Tangan</div>
                        </div>
                        <div>
                            <img class="mx-auto my-4"
                                src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/prokes2putih.svg"
                                alt="Mempelai" />
                            <div class="text-white">Menggunakan Masker</div>
                        </div>
                        <div>
                            <img class="mx-auto my-4"
                                src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/prokes3putih.svg"
                                alt="Mempelai" />
                            <div class="text-white">Menjaga Jarak</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Countdown -->
        <div class="py-10 px-6 bg-[#FFBAA6] flex items-center justify-center"
            style="max-width:70rem; margin-left: auto; margin-right: auto;">
            <div>
                <img class="h-44 sm:h-64 mx-auto" src="https://asset.menica.pro/menicav4/Save-The-Date.gif"
                    alt="Bingkai Bottom Left" />
                <div class="grid grid-cols-4 gap-3">
                    <div class="">
                        <div class="text-2xl sm:text-4xl text-white font-extrabold">{{days}}</div>
                        <div class="text-sm sm:text-base text-white font-extrabold">Hari</div>
                    </div>
                    <div class="">
                        <div class="text-2xl sm:text-4xl text-white font-extrabold">{{hours}}</div>
                        <div class="text-sm sm:text-base text-white font-extrabold">Jam</div>
                    </div>
                    <div class="">
                        <div class="text-2xl sm:text-4xl text-white font-extrabold">{{minutes}}</div>
                        <div class="text-sm sm:text-base text-white font-extrabold">Menit</div>
                    </div>
                    <div class="">
                        <div class="text-2xl sm:text-4xl text-white font-extrabold">{{seconds}}</div>
                        <div class="text-sm sm:text-base text-white font-extrabold">Detik</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Amplop -->
        <div class="py-16 px-6"
            style="max-width:70rem; margin-left: auto; margin-right: auto; background-color: #fff6f3;">
            <div class="bg-[#B47822] text-center rounded-3xl px-6 sm:px-[60px] py-3 sm:py-[30px] mx-0 sm:mx-[40px]">
                <div class="mb-8" data-aos="zoom-in" data-aos-duration="1500" data-aos-delay='500'>
                    <img class="mx-auto my-5" src="https://menica.pro/img/editor/angpaudigital2.svg" alt="Mempelai" />
                    <p class="text-lg md:text-xl text-white">AMPLOP DIGITAL</p>
                </div>
                <div class="my-6" data-aos="zoom-in" data-aos-duration="1500" data-aos-delay='500'>
                    <p class="text-white text-md">
                        Doa Restu Anda merupakan karunia yang sangat
                        berarti bagi kami.
                    </p>
                </div>
                <div class="my-6" data-aos="zoom-in" data-aos-duration="1500" data-aos-delay='500'>
                    <p class="text-white text-md">
                        Dan jika memberi adalah ungkapan tanda kasih
                        Anda, Anda dapat memberi kado secara cashless.
                    </p>
                </div>
                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4" data-aos="zoom-in" data-aos-duration="1500"
                    data-aos-delay='500'>
                    <div class="flex items-center justify-center">
                        <div class="bg-white rounded-xl text-center p-10"
                            style="color: grey; width: 100%; height: 100%;">
                            <img class="mx-auto my-5" style="max-width: 150px; max-height: 60px;"
                                src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/logo-bca.png"
                                alt="bca" />
                            <div>
                                7570694809
                                <a class="cursor-pointer font-semibold underline text-blue-500 ml-2"
                                    @click.prevent="copyData('7570694809','No. Rekening 7570694809 Berhasil dicopy')">COPY</a>
                            </div>
                            a.n : Suci Sofyati
                        </div>

                    </div>
                    <div class="flex items-center justify-center">
                        <div class="bg-white rounded-xl text-center p-10"
                            style="color: grey; width: 100%; height: 100%;">
                            <img class="mx-auto my-5" style="max-width: 150px; max-height: 60px;"
                                src="https://menica.pro/img/logos/logo-mandiri.png" alt="bca" />
                            <div>
                                0700009872743
                                <a class="cursor-pointer font-semibold underline text-blue-500 ml-2"
                                    @click.prevent="copyData('0700009872743','No. Rekening 0700009872743 Berhasil dicopy')">COPY</a>
                            </div>
                            a.n : Nu'man Nashif Annawwaf
                        </div>

                    </div>

                </div>
                <div class="flex items-center justify-center" data-aos="zoom-in" data-aos-duration="1500"
                    data-aos-delay='500'>
                    <div class="bg-white rounded-xl text-center p-10"
                        style="color: grey;width: 100%; height: 100%; max-width: 500px; margin-top: 24px;">
                        <img class="mx-auto my-5" style="max-width: 150px; max-height: 60px;"
                            src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/online-payment.png"
                            alt="e-payment" />
                        <div>
                            08990752505
                            <a class="cursor-pointer font-semibold underline text-blue-500 ml-2"
                                @click.prevent="copyData('08990752505','Nomor 08990752505 Berhasil dicopy')">COPY</a>
                        </div>
                        a.n : Suci Sofyati
                    </div>
                </div>
                <div class="mt-12" data-aos="zoom-in" data-aos-duration="1500" data-aos-delay='500'>
                    <p class="text-white text-md">
                        KIRIM HADIAH
                    </p>
                </div>
                <div class="my-12 bg-white rounded-xl p-10 text-center" style="color: #70604F;" data-aos="zoom-in"
                    data-aos-duration="1500" data-aos-delay='500'>
                    <p class="my-2">Nama Penerima : Suci Sofyati</p>
                    <p class="my-2">No Hp : 087877563610</p>
                    <p class="my-2">Gang H. Murdani Jl. Masjid Al Falah Rt 05/01 no 90 kamal, kalideres, jakarta barat. 11810</p>
                    <a class="cursor-pointer w-fit bg-[#d3b26b] rounded-xl text-center px-6 py-1 text-sm font-medium text-white mx-auto"
                        @click.prevent="copyData('Gang H. Murdani Jl. Masjid Al Falah Rt 05/01 no 90 kamal, kalideres, jakarta barat. 11810','Alamat Berhasil dicopy')">
                        Copy Alamat</a>
                </div>
            </div>
        </div>
        <!-- Bubble -->
        <div style="position:relative; max-width:70rem; margin-left:auto; margin-right:auto; text-align: center;">
            <div class="container">
                <div class="bubbles flex">
                    <span style="--i:11"></span>
                    <span style="--i:12"></span>
                    <span style="--i:24"></span>
                    <span style="--i:10"></span>
                    <span style="--i:14"></span>
                    <span style="--i:23"></span>
                    <span style="--i:18"></span>
                    <span style="--i:16"></span>
                    <span style="--i:19"></span>
                    <span style="--i:20"></span>
                    <span style="--i:22"></span>
                    <span style="--i:25"></span>
                    <span style="--i:18"></span>
                    <span style="--i:21"></span>
                    <span style="--i:13"></span>
                    <span style="--i:15"></span>
                    <span style="--i:26"></span>
                    <span style="--i:17"></span>
                    <span style="--i:13"></span>
                    <span style="--i:28"></span>
                    <span style="--i:11"></span>
                    <span style="--i:12"></span>
                    <span style="--i:24"></span>
                    <span style="--i:10"></span>
                    <span style="--i:14"></span>
                    <span style="--i:23"></span>
                    <span style="--i:18"></span>
                    <span style="--i:16"></span>
                    <span style="--i:19"></span>
                    <span style="--i:20"></span>
                    <span style="--i:22"></span>
                    <span style="--i:25"></span>
                    <span style="--i:18"></span>
                    <span style="--i:21"></span>
                    <span style="--i:13"></span>
                    <span style="--i:15"></span>
                    <span style="--i:26"></span>
                    <span style="--i:17"></span>
                    <span style="--i:13"></span>
                    <span style="--i:28"></span>
                </div>
            </div>
            <div class="hari-bahagia-text text-[14px] sm:text-[24px]">HARI BAHAGIA KAMI</div>
            <div class="menjadi-satu-text text-[20px] sm:text[40px]">
                #NUansaCIntakita</div>
            <div class="menjadi-satu-tanggal text-sm sm:textlg">
                01.10.2022</div>
        </div>
        <!-- End Bubble -->
        <!-- Buku Tamu -->
        <div class="py-16 px-6 bg-[#B47822]" id="bukutamu"
            style="max-width:70rem; margin-left: auto; margin-right: auto;">
            <div>
                <div class="mb-8 text-center">
                    <img class="mx-auto my-5" src="https://menica.pro/img/icons/bukurtamu2_active.svg" alt="Mempelai" />
                    <p class="text-lg md:text-xl text-white">B U K U T A M U</p>
                </div>
                <div class="my-12 bg-white rounded-xl p-10 text-center mx-0 sm:mx-[40px]">
                    <div class="mb-8">
                        <p class="text-lg md:text-xl font-semibold" style="color: #B47854;">Buku Tamu</p>
                        <img class="mx-auto my-5"
                            src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/dividerfloral24.svg"
                            alt="Mempelai" />
                    </div>
                    <div style="overflow-y:scroll; height:400px;" class="mb-4">
                        <div v-for="data in chats" :key="data.nama">
                            <div class="flex" style="width:100%">
                                <div class="profile mb-3 just rounded-full bg-blue flex justify-center items-center"
                                    :style="{backgroundColor: data.warna}">
                                    <div style="">{{data.nama.substring(0,1)}}
                                    </div>
                                </div>
                                <div class="card-chat">
                                    <div class="flex text-center mb-3">
                                        <div style="font-family:'Montserrat'; font-size:14px; font-weight:600">
                                            {{data.nama}}</div>
                                        <div style="width: 10px"></div>
                                        <div class="chat-ket">{{data.ket}}</div>
                                    </div>
                                    <div class="mb-3" style="font-family:'Montserrat'; font-size:12px; color:grey">
                                        {{data.waktu}}
                                    </div>
                                    <div style="font-family:'Montserrat'; font-size:14px; color:grey">{{data.ucapan}}
                                    </div>
                                </div>
                            </div>
                            <div style="height:14px"></div>
                        </div>
                    </div>
                    <div class="text-start mt-3 mb-8">
                        <form action="" @submit.prevent="sendChat">
                            <label for="fname"
                                style="font-family:'Montserrat'; font-weight: 700; color:gray; font-size: 14px;">Tulis
                                Nama</label>
                            <input type="text" id="fname" name="firstname" v-model="chat.nama">

                            <label for="message"
                                style="font-family:'Montserrat'; font-weight: 700; color:gray; font-size: 14px;">Tulis
                                Ucapan</label>
                            <div style="height:8px"></div>
                            <textarea id="message" v-model="chat.ucapan" rows="4"
                                class="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-300 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
                            <div style="height:16px"></div>
                            <label for="country"
                                style="font-family:'Montserrat'; font-weight: 700; color:gray; font-size: 14px;">Konfirmasi
                                Kehadiran</label>
                            <select class="select-ket" v-model="chat.ket" id="country" name="country"
                                style="font-family:'Montserrat'">
                                <option value="Hadir">Hadir</option>
                                <option value="Tidak Hadir">Tidak Hadir</option>
                                <option value="Masih Ragu">Masih Ragu</option>
                            </select>
                            <button v-if="!loading" type="submit" class="mt-8">
                                Kirim
                            </button>
                            <div v-if="loading" class="loader mt-8"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer -->
        <div class="relative mx-auto text-center py-10 bg-white" style="max-width:70rem; margin-bottom: 80px;">
            <div style="font-family:'Tahu'; font-size: 34px;">Thank You!</div>
            <div style="font-family:'Montserrat'; font-size: 14px;">© 2022 Develop by Nu'man</div>
        </div>
        <!--bottom navbar-->
        <div
            class="fixed bottom-5 left-1/2 transform -translate-x-1/2 inline-flex left-0 mx-auto justify-between bg-[#ff9aad] w-10/12 rounded-3xl">
            <a aria-current="page"
                class="inline-flex flex-col items-center text-xs font-medium py-3 px-4 text-white flex-grow" href="#">
                <div class="background-botnav just rounded-full bg-blue flex justify-center items-center"
                    style="color:white;">
                    <img
                        src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/beranda2_inactive.svg" />
                </div>

            </a>
            <a class="inline-flex flex-col items-center text-xs font-medium text-blue-400 py-3 px-4 flex-grow mempelai cursor-pointer"
                href="#mempelai">

                <div class="background-botnav just rounded-full bg-blue flex justify-center items-center"
                    style="color:white;">
                    <img
                        src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/mempelai2_inactive.svg" />
                </div>
            </a>
            <span class="sr-only">Upload</span>
            <a class="inline-flex flex-col items-center text-xs font-medium text-blue-400 py-3 px-4 flex-grow"
                href="#event">

                <div class="background-botnav just rounded-full bg-blue flex justify-center items-center"
                    style="color:white;">
                    <img src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/acara2.svg" />
                </div>
            </a>
            <a class="inline-flex flex-col items-center text-xs font-medium text-blue-400 py-3 px-4 flex-grow"
                href="#bukutamu">

                <div class="background-botnav just rounded-full bg-blue flex justify-center items-center"
                    style="color:white;">
                    <img
                        src="https://raw.githubusercontent.com/ourevent/ourevent.github.io/main/images/bukurtamu2_inactive.svg" />
                </div>
            </a>
        </div>
        <div
            class="fixed top-12 left-1/2 transform -translate-x-1/2 inline-flex left-0 mx-auto justify-between w-9/12 rounded-3xl">
            <audio id="music" loop>
                <source src="../assets/audio.mp3#t=00:00:12" type="audio/mp3" />
            </audio>
            <div class="background-sound just rounded-full flex justify-center items-center cursor-pointer" v-if="play"
                @click="musicControl">
                <img src="https://img.icons8.com/metro/26/ffffff/high-volume.png" />
            </div>
            <div class="background-sound just rounded-full flex justify-center items-center cursor-pointer" v-if="!play"
                @click="musicControl"><img src="https://img.icons8.com/metro/26/ffffff/mute.png" /></div>
        </div>

    </div>
</template>

<script>
import { db } from "@/services";
import { collection, onSnapshot, addDoc, query, orderBy } from "firebase/firestore";
import TheModal from "./TheModal.vue";

export default {
    name: 'MainPage',
    props: {
        user: String,
    },
    components: {
        TheModal
    },
    data() {
        return {
            countDownDate: new Date("Oct 1, 2022").getTime(),
            days: '00',
            hours: '00',
            minutes: '00',
            seconds: '00',
            open: false,
            play: false,
            loading:false,
            msg: '',
            colors: [
                "red",
                "blue",
                "green",
                "indigo",
                "purple",
                "teal",
                "orange",
                "brown",
                "deep-orange",
                "blue-grey",
                "cyan"
            ],
            chat: {
                nama: "",
                ucapan: "",
                ket: "",
            },
            chats: [],
            openinvitation: false

        }
    },
    mounted() {
        this.countDownTimer();
        onSnapshot(query(collection(db, "data"), orderBy('waktu', 'desc')), (querySnapshot) => {
            const dataChat = [];
            querySnapshot.forEach((doc) => {
                const pesan = {
                    nama: doc.data().nama,
                    ucapan: doc.data().ucapan,
                    ket: doc.data().ket,
                    waktu: doc.data().waktu,
                    warna: this.colors[Math.floor(Math.random() * this.colors.length)]
                }
                dataChat.push(pesan)
            });
            this.chats = dataChat
        });
        this.openinvitation = true;
    },
    created() {
        this.countDownTimer();

    },
    watch() {
        this.countDownTimer();
    },
    methods: {
        instagramAction(data) {
            window.location = data;
        },
        copyData(data, msg) {
            navigator.clipboard.writeText(data);
            this.open = true
            this.msg = msg;
        },
        countDownTimer() {
            window.setInterval(() => {
                var now = new Date().getTime();
                var distance = this.countDownDate - now;
                var doubleDigitParse = function (digit) {
                    if (digit < 10) {
                        return '0' + digit;
                    } else {
                        return digit;
                    }
                }
                this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
                this.hours = doubleDigitParse(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
                this.minutes = doubleDigitParse(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
                this.seconds = doubleDigitParse(Math.floor((distance % (1000 * 60)) / 1000));

                if (distance < 0) {
                    this.days = '00';
                    this.hours = '00';
                    this.minutes = '00';
                    this.seconds = '00';
                }
            }, 1000)

        },

        closeModal() {
            this.open = false
        },
        async sendChat(event) {
            event.preventDefault()
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
            var hh = String(today.getHours()).padStart(2, '0');
            var ss = String(today.getMinutes()).padStart(2, '0');


            today = mm + '/' + dd + '/' + yyyy + ' ' + hh + ':' + ss;
            if (this.chat.nama == '' || this.chat.ucapan == '' || this.chat.ket == '') {
                this.open = true
                this.msg = 'Silahkan isi nama, ucapan, dan keterangan terlebih dahulu'
            } else {
                this.loading = true;
                await addDoc(collection(db, 'data'), {
                    nama: this.chat.nama,
                    ket: this.chat.ket,
                    ucapan: this.chat.ucapan,
                    waktu: today
                })
                this.chat.nama = ''
                this.chat.ket = ''
                this.chat.ucapan = ''
                this.loading = false;
            }
        },
        goto(refName) {
            console.log('helllooo')
            var element = this.$refs[refName];
            console.log(refName)
            var top = element.offsetTop;


            window.scrollTo(0, top,);
        },
        playMusic() {
            var x = document.getElementById("music");
            x.play();
            this.play = true;
        },
        musicControl() {
            var x = document.getElementById("music");
            if (this.play) {
                x.muted = true;
                this.play = false;
            } else {
                x.muted = false;
                this.play = true;
            }
        },
        closeModalInvition() {
            this.openinvitation = false
            this.playMusic();
        },




    }
}
</script>

<style scoped>
    .loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.modal {
    position: fixed;
    overflow: hidden;
    -webkit-oveflow-scrolling: touch;
    z-index: 1;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.5);
}

.open-invitation {
    background-color: #d3b26b;
    border-radius: 100px;
    text-align: center;
    margin: auto;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 14px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    width: 40%;
    padding: 24px;
    display: table;
    border-radius: 8px;
}

/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

html {
    scroll-behavior: smooth !important;
}

.font-sacramento {
    font-family: 'Sacramento';
}

.name-welcome {
    color: #70604f;
    font-size: 55px;
    font-family: 'Sacramento';
}

#course_divider hr {
    background-color: #B47854;
    border: 1px solid #B47854;
    border-radius: 7px 7px 7px 7px;
    height: 1px;
    width: 20%;
}

.container {
    position: relative;
    width: 100%;
    max-width: 70rem;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    background: url('https://images.unsplash.com/reserve/m6rT4MYFQ7CT8j9m2AEC_JakeGivens%20-%20Sunset%20in%20the%20Park.JPG');
    background-size: 100% 300px;
    overflow: hidden;
    filter: brightness(70%);
}

.bubbles {
    position: relative;
}

.bubbles span {
    position: static;
    min-width: 15px;
    height: 15px;
    background: #f2a90080;
    box-shadow: 0 0 0 10px #f2a90080,
        0 0 50px #f2a90080,
        0 0 100px #f2a90080;
    margin: 0 4px;
    border-radius: 50%;
    animation: animate 15s linear infinite;
    animation-duration: calc(75s / var(--i));
}

.bubbles span:nth-child(even) {
    background: #f2a90080;
    box-shadow: 0 0 0 10px #f2a90080,
        0 0 50px #f2a90080,
        0 0 100px #f2a90080;
}

@keyframes animate {
    0% {
        transform: translateY(100vh) scale(0);
    }

    100% {
        transform: translateY(-10vh) scale(1);
    }
}

.hari-bahagia-text {
    color: white;
    font-weight: 800;
    position: absolute;
    top: 80px;
    right: 0;
    left: 0;
    text-align: center;
    bottom: 0;
}

.menjadi-satu-text {
    color: white;
    font-weight: 800;
    position: absolute;
    top: 140px;
    right: 0;
    left: 0;
    text-align: center;
    bottom: 0;
}

.menjadi-satu-tanggal {
    color: white;
    font-weight: 800;
    position: absolute;
    top: 220px;
    right: 0;
    left: 0;
    text-align: center;
    bottom: 0;
}

.chat-ket {
    background-color: #d3b26b;
    border-radius: 4px;
    text-align: center;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 10px;
    font-family: 'Montserrat';
    font-weight: 500;
    color: white;
}

.card-chat {
    background-color: white;
    margin-left: 10px;
    border-radius: 16px;
    padding: 16px;
    height: fit-content;
    width: 100%;
    margin-right: 10px;
    text-align: start;
    box-shadow: 2px 3px 5px grey;
}

.background-sound {
    background-color: green;
    border-radius: 100%;
    text-align: center;
    min-height: 40px;
    min-width: 40px;
    max-width: 40px;
    max-height: 40px;
    position: relative;
    font-size: 18px;
    font-family: 'Montserrat';
    font-weight: 700;
    color: white
}

.background-botnav {
    background-color: white;
    border-radius: 100%;
    text-align: center;
    min-height: 40px;
    min-width: 40px;
    max-width: 40px;
    max-height: 40px;
    position: relative;
    font-size: 18px;
    font-family: 'Montserrat';
    font-weight: 700;
    color: white
}

.profile {
    border-radius: 100%;
    text-align: center;
    min-height: 40px;
    min-width: 40px;
    max-width: 40px;
    max-height: 40px;
    position: relative;
    font-size: 18px;
    font-family: 'Montserrat';
    font-weight: 700;
    color: white
}

.select-ket {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

input[type=text],
select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

button[type=submit] {
    width: fit-content;
    background-color: #ceaa4a;
    color: white;
    padding: 4px 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    border: none;
    border-radius: 100px;
    cursor: pointer;
}

input[type=submit]:hover {
    background-color: #45a049;
}
</style>